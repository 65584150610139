import { Component, Inject } from '@angular/core';
import { addDoc, collection, doc, Firestore, setDoc } from '@angular/fire/firestore';
import { Auth, authState } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuelWithId } from '../types';

@Component({
  selector: 'app-add-fuel',
  templateUrl: './add-fuel.component.html',
  styleUrls: ['../common-styles.css'],
  standalone: false,
})
export class AddFuelComponent {
  user: string | undefined;
  fuelForm: FormGroup;
  verb: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { vehicle: string; fuel: FuelWithId | undefined },
    public dialogRef: MatDialogRef<AddFuelComponent>,
    public firestore: Firestore,
    public auth: Auth,
  ) {
    if (data.fuel !== undefined) {
      this.verb = 'Edit';
      this.fuelForm = new FormGroup({
        date: new FormControl(data.fuel.date.toDate(), Validators.required),
        location: new FormControl(data.fuel.location, Validators.required),
        odometer: new FormControl(data.fuel.odometer, Validators.required),
        price: new FormControl(data.fuel.price, Validators.required),
        pricePerGallon: new FormControl(data.fuel.pricePerGallon, Validators.required),
        grade: new FormControl(data.fuel.grade),
      });
    } else {
      this.verb = 'Add';
      this.fuelForm = new FormGroup({
        date: new FormControl(new Date(), Validators.required),
        location: new FormControl('', Validators.required),
        odometer: new FormControl('', Validators.required),
        price: new FormControl('', Validators.required),
        pricePerGallon: new FormControl('', Validators.required),
        grade: new FormControl('Regular'),
      });
    }
    authState(this.auth).subscribe((user) => {
      this.user = user?.uid;
    });
  }

  onSubmit() {
    const fuelCollection = collection(
      doc(collection(this.firestore, 'vehicles'), this.data.vehicle),
      'fuel',
    );

    const fuel = {
      date: this.fuelForm.value['date'],
      location: this.fuelForm.value['location'],
      odometer: this.fuelForm.value['odometer'],
      price: this.fuelForm.value['price'],
      pricePerGallon: this.fuelForm.value['pricePerGallon'],
      grade: this.fuelForm.value['grade'],
      owner: this.user,
    };
    if (this.data.fuel !== undefined) {
      setDoc(doc(fuelCollection, this.data.fuel.id), fuel).then(() => {
        this.dialogRef.close();
      });
    } else {
      addDoc(fuelCollection, fuel).then(() => {
        this.dialogRef.close();
      });
    }
  }
}
