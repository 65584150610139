import { Component, Inject } from '@angular/core';
import { addDoc, collection, doc, Firestore, updateDoc } from '@angular/fire/firestore';
import { Auth, authState } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VehicleWithId } from '../types';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['../common-styles.css'],
  standalone: false,
})
export class AddVehicleComponent {
  user: string | undefined;
  vehicleForm: FormGroup;
  verb: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { vehicle: VehicleWithId },
    public dialogRef: MatDialogRef<AddVehicleComponent>,
    public firestore: Firestore,
    public auth: Auth,
  ) {
    if (data !== null) {
      this.verb = 'Edit';
      this.vehicleForm = new FormGroup({
        make: new FormControl(data.vehicle.make, Validators.required),
        model: new FormControl(data.vehicle.model, Validators.required),
        year: new FormControl(data.vehicle.year, Validators.required),
        color: new FormControl(data.vehicle.color),
        plates: new FormControl(data.vehicle.plates),
      });
    } else {
      this.verb = 'Add';
      this.vehicleForm = new FormGroup({
        make: new FormControl('', Validators.required),
        model: new FormControl('', Validators.required),
        year: new FormControl('', Validators.required),
        color: new FormControl(''),
        plates: new FormControl(''),
      });
    }
    authState(this.auth).subscribe((user) => {
      this.user = user?.uid;
    });
  }

  onSubmit() {
    const vehicle = {
      make: this.vehicleForm.value['make'],
      model: this.vehicleForm.value['model'],
      year: this.vehicleForm.value['year'],
      color: this.vehicleForm.value['color'],
      plates: this.vehicleForm.value['plates'],
      owner: this.user,
    };
    if (this.data !== null) {
      updateDoc(doc(this.firestore, 'vehicles', this.data.vehicle.id), vehicle).then(() => {
        this.dialogRef.close();
      });
    } else {
      addDoc(collection(this.firestore, 'vehicles'), vehicle).then(() => {
        this.dialogRef.close();
      });
    }
  }
}
