import { Component, Inject } from '@angular/core';
import { addDoc, collection, doc, Firestore, setDoc } from '@angular/fire/firestore';
import { Auth, authState } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceWithId } from '../types';

@Component({
  selector: 'app-add-maintenance',
  templateUrl: './add-maintenance.component.html',
  styleUrls: ['../common-styles.css'],
  standalone: false,
})
export class AddMaintenanceComponent {
  user: string | undefined;
  maintenanceForm: FormGroup;
  verb: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { vehicle: string; maintenance: MaintenanceWithId | undefined },
    public dialogRef: MatDialogRef<AddMaintenanceComponent>,
    public firestore: Firestore,
    public auth: Auth,
  ) {
    if (data.maintenance !== undefined) {
      this.verb = 'Edit';
      this.maintenanceForm = new FormGroup({
        date: new FormControl(data.maintenance.date.toDate(), Validators.required),
        location: new FormControl(data.maintenance.location, Validators.required),
        odometer: new FormControl(data.maintenance.odometer, Validators.required),
        price: new FormControl(data.maintenance.price, Validators.required),
        category: new FormControl(data.maintenance.category),
        description: new FormControl(data.maintenance.description),
      });
    } else {
      this.verb = 'Add';
      this.maintenanceForm = new FormGroup({
        date: new FormControl(new Date(), Validators.required),
        location: new FormControl('', Validators.required),
        odometer: new FormControl('', Validators.required),
        price: new FormControl('', Validators.required),
        category: new FormControl(''),
        description: new FormControl(''),
      });
    }
    authState(this.auth).subscribe((user) => {
      this.user = user?.uid;
    });
  }

  onSubmit() {
    const maintenanceCollection = collection(
      doc(collection(this.firestore, 'vehicles'), this.data.vehicle),
      'maintenance',
    );

    const maintenance = {
      date: this.maintenanceForm.value['date'],
      location: this.maintenanceForm.value['location'],
      odometer: this.maintenanceForm.value['odometer'],
      price: this.maintenanceForm.value['price'],
      category: this.maintenanceForm.value['category'],
      description: this.maintenanceForm.value['description'],
      owner: this.user,
    };
    if (this.data.maintenance !== undefined) {
      setDoc(doc(maintenanceCollection, this.data.maintenance.id), maintenance).then(() => {
        this.dialogRef.close();
      });
    } else {
      addDoc(maintenanceCollection, maintenance).then(() => {
        this.dialogRef.close();
      });
    }
  }
}
