import { Auth, authState, User } from '@angular/fire/auth';
import { Component } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  standalone: false,
})
export class HomepageComponent {
  public readonly user: Observable<User | null> = EMPTY;

  constructor(public auth: Auth) {
    this.user = authState(this.auth);
  }
}
